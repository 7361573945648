import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54c08752"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "subject" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "center" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { class: "right" }



// import defaultItemIcon from "@/assets/img_defaultItemIcon.png"


export default {
  __name: 'ToolItem',
  props: {
  tools: {
    type: Object,
    default: () => ({
      icon: null,
      name: null,
      license: null,
      link: null,
      description: null,
    }),
  },
},
  setup(__props) {

const props = __props;



return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("a", {
    href: props.tools.link,
    target: "_blank"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "icon",
        style: _normalizeStyle(`background: url(${props.tools.icon})`)
      }, null, 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(props.tools.name), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(props.tools.description), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(props.tools.description), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", {
        class: _normalizeClass(["license", props.tools.license])
      }, _toDisplayString(props.tools.license), 3)
    ])
  ], 8, _hoisted_1))
}
}

}