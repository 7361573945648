import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-600b613c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label" }




export default {
  __name: 'ToolsCollection',
  props: {
  collection: Object,
},
  setup(__props) {

const props = __props;



return (_ctx, _cache) => {
  const _component_ToolItem = _resolveComponent("ToolItem")

  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(props.collection.label), 1)
    ]),
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.collection.tools, (tools) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "item",
          key: tools
        }, [
          _createVNode(_component_ToolItem, { tools: tools }, null, 8, ["tools"])
        ]))
      }), 128))
    ])
  ]))
}
}

}