import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb81c058"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chip" }




export default {
  __name: 'Chip',
  props: {
  label: String,
},
  setup(__props) {

const props = __props;



return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(__props.label), 1)
  ]))
}
}

}